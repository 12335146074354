<template>
<div>

<section id="Modes" class="ma-0 grey lighten-2" style="color: #000" >

<v-container class="content-box">
  <div class="titleDiv px-5">
    <p class="display-1">Learning Culture</p>
  </div>
  <div class="contentCard">
  <div class="my-5 py-5">
  <p class="subtitle-1">
    Kalalaya is run by highly industrial experts 
    who have won multiple awards for performing their 
    research and development in the field of learning and development. 
    In the modern era, students and millennials don’t love just traditional 
    way of learning in the classroom but in a more versatile way. 
    The research fact says that students do not love to study in a 
    typical classroom set up. Therefore Kalalaya sets a new trend in the field of 
    school education by offering learning in different modes of learning 
    and in an interesting and lively learning environment thereby making 
    its learning culture more robust and attractive. 
    This methodology has proven its significance by making a transformation in a lot of so called “slow-learners”.
  </p>
  </div>
  </div>
</v-container>

<article class="teal modes-box" style="color: #eee">
<v-container class="my-5">
  <p class="display-1 mt-5 mb-5">Traditional Instructor Led Training</p>
  <v-row>
    <v-col cols="12" sm="12" md="6" order="2" order-sm="1" class="my-5 py-5 d-flex align-center justify-center">
        <div>
          <div class="headline font-italic">
            <p class="mb-0">"In learning you will teach, and in teaching you will learn"</p>
            <p class="subtitle-2 font-italic">- Phil Collins</p>
          </div>
            
          <p class="subtitle-1 mt-5 pt-5">
          Learning implementation led by a teacher / trainer / coach with the students.
          Traditional way of learning &amp; teaching has its
          own significance. 60% of training and teaching
          are done through instructor led ways. In simple
          terms, a teacher in a classroom teaches a group
          of students.
          </p>
        </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" order="1" order-sm="2" class="my-5 py-5 d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <img src="../../assets/images/teacher-pic.svg" alt="" height="300px" width="99%">
      </div>
    </v-col>
  </v-row>
</v-container>
</article>

<article class="indigo darken-2 modes-box" style="color: #eee">
<v-container class="my-5">
  <p class="display-1 mt-5 mb-5">Gamification</p>
  <v-row>
    <v-col cols="12" sm="12" md="6" class="my-5 py-5 d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <img src="../../assets/images/game-pic.svg" alt="" height="300px" width="99%">
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" class="my-5 py-5 d-flex align-center justify-center">
        <div>
         
          <div class="headline font-italic">
            <p class="mb-0">"Games are the only force in the known universe that can get people to take actions against their self-interest,
                             in a predictable way, without using force"</p>
            <p class="subtitle-2 font-italic">- Gabe Zichermann</p>
          </div>

          <p class="subtitle-1 mt-5">
          Insightful games on soft skills are executed at a regular frequency.
          Which child doesn’t love playing games? 
          In the modern era, children or any learner 
          is so occupied in playing games via digital gadgets or socially, 
          to a little extent. So, Kalalaya mandates ‘Fun Fridays’ 
          every week through which students are taught insightful 
          games to play on the floor to improve their Communication Skills, 
          Time Management, to inculcate good habits, listening skills, etc. 
          </p>
        </div>
    </v-col>
  </v-row>
</v-container>
</article>

<article class="deep-purple modes-box" style="color: #eee">
<v-container class="my-5">
  <p class="display-1 mt-5 mb-5">Activity Based</p>
  <v-row>
    <v-col cols="12" sm="12" md="6" order="2" order-sm="1" class="my-5 py-5 d-flex align-center justify-center">
        <div>
          <div class="headline font-italic">
            <p class="mb-0">"For the things we have to learn before we can do them, we learn by doing them"</p>
            <p class="subtitle-2 font-italic">- Aristotle</p>
          </div>
            
          <p class="subtitle-1 mt-5 pt-5">
           Students are given with regular hands-on activities to experience their learning.
           Implementation of learning is highly 
           critical to see outcome of any learning & 
           one should be very careful in practicing it after learning. 
           So it has to be properly guided, mentored & evaluated. 
           For any learning students take up here, 
           hands-on activities are given in the classroom and 
           as simple home assignments so that the learning implementation takes place.
          </p>
        </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" order="1" order-sm="2" class="my-5 py-5 d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <img src="../../assets/images/activity-pic.svg" alt="" height="300px" width="99%">
      </div>
    </v-col>
  </v-row>
</v-container>
</article>

<article class="blue-grey darken-1 modes-box" style="color: #eee">
<v-container class="my-5">
  <p class="display-1 mt-5 mb-5">Role Plays</p>
  <v-row>
    <v-col cols="12" sm="12" md="6" class="my-5 py-5 d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <img src="../../assets/images/role-pic.svg" alt="" height="300px" width="99%">
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" class="my-5 py-5 d-flex align-center justify-center">
        <div>
          <div class="headline font-italic">
            <p class="mb-0">"The essence of Role Playing game is that it is a group, cooperative experience"</p>
            <p class="subtitle-2 font-italic">- Gary Gygax</p>
          </div>
            
          <p class="subtitle-1 mt-5 pt-5">
          Role Plays are executed among the students to enhance their learning proficiency.
          Situational learning is equally important for any learner. 
          Role Plays are very unique in helping or moulding students 
          to be situationally aware and apply their learning more appropriately. 
          By products of Role plays are getting rid of stage fright and becoming presentable. 
          Kalalaya makes it a practice often teaching their students through role plays as well.  
          </p>
        </div>
    </v-col>
  </v-row>
</v-container>
</article>

<article class="primary modes-box" style="color: #eee">
<v-container class="my-5">
  <p class="display-1 mt-5 mb-5">Book Reading</p>
  <v-row>
    <v-col cols="12" sm="12" md="6" order="2" order-sm="1" class="my-5 py-5 d-flex align-center justify-center">
        <div>
         
          <div class="headline font-italic">
            <p class="mb-0">"Never trust anyone who has not brought a book with them"</p>
            <p class="subtitle-2 font-italic">- Lemony Snicket</p>
          </div>

          <p class="subtitle-1 mt-5">
           After the inception of Digitisation & Cell phones, 
           merely a little amount of students read books and 
           this wondeful habit is unfortunately not inculcated by 
           many schools and even parents. Research says that human brain 
           becomes so articulated and matured if one reads book regularly. 
           Kalalaya has a mini physical library for its students to cultivate 
           this nice habit by fetching books at a free of cost. 
           Thankfully, few elite parents also contribute their read books to the Kalalaya Library. 
          </p>
        </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" order="1" order-sm="2" class="my-5 py-5 d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <img src="../../assets/images/book-pic.svg" alt="" height="300px" width="99%">
      </div>
    </v-col>
  </v-row>
</v-container>
</article>


<article class="grey lighten-1 modes-box" style="color: #000">
<v-container class="my-5">
  <p class="display-1 mt-5 mb-5">Video Learning</p>
  <v-row>
    <v-col cols="12" sm="12" md="6" class="my-5 py-5 d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <img src="../../assets/images/video-pic.svg" alt="" height="300px" width="99%">
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" class="my-5 py-5 d-flex align-center justify-center">
        <div>
          <div class="headline font-italic">
            <p class="mb-0">"A Human brain processes images 60,000 times faster than text, and 90 percent of information transmitted to the brain is visual."</p>
            <p class="subtitle-2 font-italic">- A Science Fact</p>
          </div>
            
          <p class="subtitle-1 mt-5 pt-5">
           Videos are used as a tool to teach key concepts in a theatrical way every week.
           No child or student says ‘No’ to visual learning & colourful learning. 
           A chapter of 200 pages can be taught though a 20 min video 
           in a more compelling way that the students don’t forget the concepts they learn. 
           One can easily relate the importance of video learning 
           from the way kids remember the super heroes, heroines, 
           dialogues and mainly the sequence of stories and key incidents in the movie.
          </p>
        </div>
    </v-col>
  </v-row>
</v-container>
</article>


<article class="teal modes-box" style="color: #eee">
<v-container class="my-5">
  <p class="display-1 mt-5 mb-5">One On One Coaching</p>
  <v-row>
    <v-col cols="12" sm="12" md="6" order="2" order-sm="1" class="my-5 py-5 d-flex align-center justify-center">
        <div>
         
          <div class="headline font-italic">
            <p class="mb-0">"Coaching is unlocking a person’s potential to maximise their growth"</p>
            <p class="subtitle-2 font-italic">- John Whitmore</p>
          </div>

          <p class="subtitle-1 mt-5">
           Professional coaching to students & adults by an ICF certified coach to improve 
           their performance or deepen their awareness.
           Coaching is not about Counselling, teaching, training, advising or consulting. 
           It’s about the process of deepening and exploring one’s thought to take a 
           good decision and accordingly decide on the action items by self. 
           The Co-Founder of Kalalaya is himself an ICF(International Coaching Federation) 
           certified Tranformative Coach, whose expertise assists Kalalaya students shine best. 
          </p>
        </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" order="1" order-sm="2" class="my-5 py-5 d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <img src="../../assets/images/father-pic.svg" alt="" height="300px" width="99%">
      </div>
    </v-col>
  </v-row>
</v-container>
</article>


</section>

</div>
</template>

<script>
export default {
    name: 'modesoflearning'
}
</script>

<style scoped>
#Modes{
  padding-top: 7rem !important;
  scroll-behavior: smooth !important;
}
.content-box{
  padding-bottom: 4rem;
}
.titleDiv{
  border-left: 10px solid #000;
  margin-bottom: 2rem;
}
.modes-box{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
</style>
